<template>
  <TsSection class="md:mt-2 mt-4">
    <TsTypography append-class="mb-2" weight="normal" as="h3">
      No savelist found!
    </TsTypography>
    <TsTypography append-class="mb-4" wei   ght="normal" as="h4">
      Create a new savelist to begin!
    </TsTypography>

    <NuxtImg
      width="90"
      class="text-center mx-auto"
      src="/images/searchResultNotFound.svg"
      loading="lazy"
    />
  </TsSection>
</template>
<script setup lang="ts"></script>
<style></style>
